// @flow

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { create as createApiClient } from "../api";
import reducer from "./modules";

/**
 * Create the store
 *
 * TODO: Add check if in dev mode or in prod mode
 */
export const create = (): * => {
    const storeFactory = applyMiddleware(
        thunk.withExtraArgument(createApiClient),
    )(createStore);

    const store = storeFactory(
        reducer,
        (typeof window !== "undefined" &&
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()) ||
            undefined,
    );

    return store;
};
