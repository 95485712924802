module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Saleshub","short_name":"Saleshub","start_url":"/","background_color":"#3b3b39","theme_color":"#3b3b39","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7177d952a09f8bcafa423e1c2b98080f"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://5c6666ca45a04a91a7ff5dc01edefd36@o894871.ingest.sentry.io/5840604"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
