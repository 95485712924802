// @flow

import { combineReducers } from "redux";

import user from "./user";

/**
 * The root reducer
 */
export default combineReducers<*, *>({ user });
