// @flow

/* eslint-env browser */
// /* global __PATH_PREFIX__ */

import React from "react";
import { Provider } from "react-redux";

import "./src/styles/index.scss";
import "@2po-dgp/components/build/esm/index.css";
import { create } from "./src/redux";

const store = create();

export const wrapRootElement = ({ element }: *) => (
    <Provider store={store}>{element}</Provider>
);

const gtmScriptContent = (gtmId: string) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

const gtmIframe = (gtmId: string) => `
<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

const insertHeadScript = (gtmId: *) => {
    const addJS = () => {
        const s = document.createElement(`script`);
        s.type = `text/javascript`;
        s.innerHTML = gtmScriptContent(gtmId);
        document.getElementsByTagName(`head`)[0].appendChild(s);
    };

    addJS();
};

const insertIframe = (gtmId: *) => {
    const addJS = () => {
        const s = document.createElement(`noscript`);
        s.innerHTML = gtmIframe(gtmId);
        document.getElementsByTagName(`body`)[0].appendChild(s);
    };

    addJS();
};

const injectGTMSCripts = (gtmId: *) => {
    insertHeadScript(gtmId);
    insertIframe(gtmId);
};

export const onInitialClientRender = () => {
    const gtmId = process.env.GATSBY_GTM_ID || "";
    if (gtmId) {
        setTimeout(() => {
            injectGTMSCripts(gtmId);
        }, 3000);
    }
};
