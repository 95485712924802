// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule("user", () => "/backend/user");

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
