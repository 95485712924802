// @flow

import axios from "axios";

export const create = () => {
    const client = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: status => status >= 200 && status < 400,
    });

    // Redirect to logout when 401 is intercepted
    client.interceptors.response.use(null, error => {
        if (error.response.status === 401) {
            window.location.href =
                process.env.GATSBY_LOGIN_URL || "/backend/saml20/login";
        }
        throw error;
    });

    return client;
};
